<template>
  <div class="login_container">
    <!-- 在vue 当中所有的html内容 都只能有一个最大的父级 div -->
    <div class="login_box">
      <el-form
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
        label-width="80px"
        class="login_form">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" prefix-icon="iconfont icon-yonghu"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="loginForm.password" prefix-icon="iconfont icon-mima" show-password></el-input>
        </el-form-item>
        <!-- 登录/重置 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="handleLogin" :loading="islogin">登录</el-button>
          <el-button type="info" @click="handleLoginReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from '@/services/user.js'
export default {
  name: 'Login',
  data () {
    return {
      islogin: false,
      loginForm: {
        username: '13800138007',
        password: '123456'
      },
      // 表单验证的规则
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async handleLogin () {
      try {
        // 设置校验
        await this.$refs.loginFormRef.validate()

        // 检验输入信息后 更改登录按钮状态
        this.islogin = true

        // 发送请求
        const { data: res } = await login({
          ...this.loginForm,
          operation: 'login'
        })
        console.log(res, '<--登录成功返回信息')
        if (res.status === 1) {
          this.$message.success('登录成功')
          // console.log(document.cookie, '<--cookie信息')
          this.$store.commit('setUser', '["access_token","admin|d0406409b7b90c5dd3a75acb1a24c2854c68324e97b46815c3adc1060f0e292926dca945ac6031b26d85d8e40a226515bf30d877cae35e84d004ae0a0903131f')
          this.$router.push(this.$route.query.redirect || '/')
          // 登录成功后，将用户信息保存在 vuex 中，后续方便跨组件使用
          this.$store.commit('saveUser', this.loginForm.username)
        } else {
          this.$message.error(res.data !== null ? res.data : '登录失败')
        }
        // 获取服务端信息后 更改登录按钮状态
        this.islogin = false
      } catch (error) {
        // 设置失败后的提示
        this.$message.warning('信息校验失败')
        this.islogin = false
      }
    },
    handleLoginReset () {
      this.$refs.loginFormRef.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
  .login_container{
    height: 100%;
    width: 100%;
    background-color: $primary-color;
  }
  .login_box{
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .login_form{
    position: absolute;
    bottom: 30px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .btns{
    display: flex;
    justify-content: flex-end;
  }
</style>
